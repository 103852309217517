import { useEffect, useState, createContext } from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import axios from "axios";
import Menu from "./components/menu/menu";
import Page from "./components/pages/page/page";
import Error from "./components/error/error";
import Footer from "./components/footer/footer";
import Product from "./components/pages/product/product";
import ScrollToTop from "./components/utilities/scrolltotop/scrolltotop";
import Meta from "./components/content/meta/meta";
import Industry from "./components/pages/industry/industry";
import News from "./components/news/news";
import CookieConsent from "react-cookie-consent";
import Redirector from "./components/utilities/redirector/redirector";

export const AppContext = createContext<any>(null);

function App() 
{
    const [languages, SetLanguages] = useState<any>(null);
    const [lang, SetLang] = useState<string>(null);
    const [footer, SetFooter] = useState<any>(null);
    const [info, SetInfo] = useState<any>(null);
    const [meta, SetMeta] = useState<any>(null);
    const [menu, SetMenu] = useState<any>(null);
    const [products, SetProducts] = useState<any>(null);
    const [productsSlug, SetProductsSlug] = useState<string>(null);
    const [industriesSlug, SetIndustriesSlug] = useState<string>(null);
    const [newsSlug, SetNewsSlug] = useState<string>(null);

    // First get a list of active languages
    useEffect(() => {
        axios
            .get(process.env.STRAPI_URL + '/api/i18n/locales')
            .then((response) => {
                SetLanguages(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Then infer the current language from the URL
    useEffect(() => 
    {  
        if(languages == null) return;

        const cleanPath = location.pathname.replace(process.env.BASE_PATH, '/');
        const firstPath = cleanPath.split('/')[1];
        let urlLanguage:string = null;
        
        languages.forEach((l:any) =>
        {
            if(l.code == firstPath) urlLanguage = l.code; 
        });

        if(urlLanguage)
            SetLang(urlLanguage);
        else
            SetLang('en');

    }, [languages]);

    // Then get the main content
    useEffect(() =>
    {  
        if(lang == null) return;

        console.log("Getting data for: " + lang);

        // Get menu
        axios
            .get(process.env.STRAPI_URL + '/api/menu?populate=pages,submenuProduct,featuredProduct,featuredImage&locale=' + lang)
            .then((response) => {
                SetMenu(response.data.data.attributes);
            })
            .catch((error) => {
                console.log(error);
            });
        
        // Get products
        axios
            .get(process.env.STRAPI_URL + '/api/products?filters[parent][$null]=true&sort=order&locale=' + lang)
            .then((response) => {
                SetProducts(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Footer
        axios
            .get(process.env.STRAPI_URL + '/api/footer?populate=background,image,links&locale=' + lang)
            .then((response) => {
                SetFooter(response.data.data.attributes);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Info
        axios
            .get(process.env.STRAPI_URL + '/api/info?populate=&locale=' + lang)
            .then((response) => {
                SetInfo(response.data.data.attributes);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Meta
        axios
            .get(process.env.STRAPI_URL + '/api/meta?populate=deep,4&locale=' + lang)
            .then((response) => {
                SetMeta(response.data.data.attributes.data);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get product slug
        axios
            .get(process.env.STRAPI_URL + '/api/pages?filters[slug][$eq]=products&locale=en&populate=localizations')
            .then((response) => {
                if(lang == 'en')
                {
                    SetProductsSlug(response.data.data[0].attributes.slug);
                }
                else
                {
                    response.data.data[0].attributes.localizations.data.forEach((l:any) =>
                    {
                        if(l.attributes.locale == lang) SetProductsSlug(l.attributes.slug);
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

        // Get industries slug
        axios
            .get(process.env.STRAPI_URL + '/api/pages?filters[slug][$eq]=industries&locale=en&populate=localizations')
            .then((response) => {
                if(lang == 'en')
                {
                    SetIndustriesSlug(response.data.data[0].attributes.slug);
                }
                else
                {
                    response.data.data[0].attributes.localizations.data.forEach((l:any) =>
                    {
                        if(l.attributes.locale == lang) SetIndustriesSlug(l.attributes.slug);
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

            
        // Get news slug
        axios
        .get(process.env.STRAPI_URL + '/api/pages?filters[slug][$eq]=news&locale=en&populate=localizations')
        .then((response) => {
            if(lang == 'en')
            {
                SetNewsSlug(response.data.data[0].attributes.slug);
            }
            else
            {
                response.data.data[0].attributes.localizations.data.forEach((l:any) =>
                {
                    if(l.attributes.locale == lang) SetNewsSlug(l.attributes.slug);
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
        
    }, [lang]);

    return (
        <>
            {lang && languages && menu && products && info && productsSlug && meta && newsSlug && industriesSlug && 
                <AppContext.Provider value={{ lang: lang, products: products, productsSlug: productsSlug, industriesSlug: industriesSlug, newsSlug: newsSlug, SetLang: SetLang }}>
                    <BrowserRouter>
                        <Redirector />
                        <Meta content={meta} />
                        <ScrollToTop />
                        <Menu menu={menu} info={info} languages={languages} />
                        <div className="min-h-screen">
                        <Routes>
                            <Route path={process.env.BASE_PATH} errorElement={<Error />}>
                                <Route path={lang == 'en' ? '' : lang} key={lang}>
                                    <Route index element={<Page slug={'home'} />} />
                                    <Route path={productsSlug}>
                                        <Route index element={<Page slug={productsSlug} />} />
                                        <Route path=":productSlug" element={<Product />} />
                                        <Route path=":productSlug/faq/:question" element={<Product />} />
                                    </Route>
                                    <Route path={industriesSlug}>
                                        <Route index element={<Page slug={industriesSlug} />} />
                                        <Route path=":industrySlug" element={<Industry />} />
                                    </Route>
                                    <Route path={newsSlug}>
                                        <Route index element={<News />} />
                                        <Route path="page/:index" element={<News />} />
                                        <Route path=":slug" element={<News />} />
                                    </Route>
                                    <Route path=":page">
                                        <Route index element={<Page />} />
                                        <Route path=":subpage" element={<Page />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                        </div>
                        {footer && info && products && 
                            <Footer content={footer} info={info} />
                        }
                        <CookieConsent
                            style={{ background: "#3B5790" }}
                            buttonStyle={{ background: "#ffffff", color: "#3B5790", borderRadius:"6px" }}
                            buttonText='Accept'
                            onAccept={(acceptedByScrolling:boolean) => 
                            {
                                if (!acceptedByScrolling)
                                {
                                    let consentSettings = {
                                        'ad_storage': 'denied',
                                        'ad_personalization': 'denied',
                                        'ad_user_data': 'denied',
                                        'analytics_storage': 'granted'
                                    }

                                    // Save consentSettings in local storage
                                    localStorage.setItem('consentSettings', JSON.stringify(consentSettings));

                                    // Refresh for gtag
                                    location.reload();
                                }
                            }}
                        >
                            This website uses cookies for analytics purposes. For more info visit our <NavLink to='/privacy-policy'>Privacy Policy</NavLink>.
                        </CookieConsent>
                    </BrowserRouter>
                </AppContext.Provider>
            }
        </>
    );
}

export default App;