import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../App";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Content from "../../content/content";

function Industry(props:any) {

    const { lang } = useContext(AppContext);
    const [industry, SetIndustry] = useState<any>(null);
    const [contentId, SetContentId] = useState<number>(0);
    const {industrySlug} = useParams();

    useEffect(() => {  
        axios
            .get(process.env.STRAPI_URL + '/api/industries?filters[slug][$eq]=' + industrySlug + '&populate=deep,4&locale=' + lang)
            .then((response) => {
                SetIndustry(response.data.data[0].attributes);
                SetContentId(response.data.data[0].id);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [lang, industrySlug])

    return (
        <>
            {industry && contentId && 
                <div>
                    <Content content={industry.content} contentId={contentId} />
                </div>
            }
        </>
    );
}

export default Industry;