import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
import GetImageSize from '../../utilities/getimagesize/getimagesize';
import Markdown from 'react-markdown'

function CallToActon(props: any) {
    
    return (
        <div className='w-full pb-6 text-sm md:text-base text-white bg-neutral-800 pt-7'>
            <ContentWrapper>
                <div className="md:flex gap-8 items-start">
                    <motion.div
                        className='hidden md:block shrink-0'
                        initial={{ scale: 1, rotate: 180 }}
                        whileInView={{ rotate: 0, scale: 1 }}
                        viewport={{ amount: 'all' }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}>
                        <img src={GetImageSize(props.content.icon, 'xsmall')} className="h-12 -mt-2 -mb-1" />
                    </motion.div>
                    <div className="grow mt-1">
                        <Markdown>{props.content.text}</Markdown>
                    </div>
                    {props.content.button &&    
                        <div className='shrink-0 mt-8 md:mt-1'>
                            <Button button={props.content.button} />
                        </div> 
                    }
                </div>
            </ContentWrapper>
        </div>
    );
}

export default CallToActon;