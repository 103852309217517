import { motion } from 'framer-motion';
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import GetImageSize from "../../utilities/getimagesize/getimagesize";
import Markdown from 'react-markdown';

function ProductVideo(props: any) {

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className='w-full py-20'>
                    <ContentWrapper>
                        <div className="text-center">
                            {props.content.logo.data &&
                                <div className="mb-12">
                                    <img src={GetImageSize(props.content.logo, 'small')} className='h-24 block mx-auto' />
                                </div>
                            }
                            {props.content.title &&
                                <div className="text-4xl lg:text-5xl font-bold mb-12">
                                    {props.content.title}
                                </div>
                            }
                            {props.content.text && 
                                <div className="max-w-4xl mx-auto mb-12">
                                    <Markdown>{props.content.text}</Markdown>
                                </div>
                            }
                            {props.content.video && props.content.video.providerUid && 
                                <div className='mx-auto max-w-6xl mb-12'>
                                    <iframe className='aspect-video w-full' src={"https://www.youtube.com/embed/" + props.content.video.providerUid} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            }
                            {props.content.partnersTitle && 
                                <div className="mb-4">
                                    {props.content.partnersTitle}
                                </div>
                            }
                            {props.content.partnersLogo.data &&
                                <div className="mb-12">
                                    <img src={GetImageSize(props.content.partnersLogo, 'small')} className='h-24 block mx-auto' />
                                </div>
                            }
                        </div>
                    </ContentWrapper> 
                </div>
            </motion.div>
        </>
    );
}

export default ProductVideo;