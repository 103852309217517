import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../App";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Content from "../../content/content";

function Page(props:any) {

    const { lang } = useContext(AppContext);
    const [content, SetContent] = useState<any>(null);
    const [contentId, SetContentId] = useState<number>(0);
    const {page} = useParams();
    const {subpage} = useParams();

    useEffect(() => {  
        
        let slug = "";
        let path = "";

        if(props.slug)
        {
            slug = props.slug;
            path = props.slug;
        }
        else
        {
            slug = page;
            path = page;

            if(subpage)
            {
                slug = subpage;
                path = page + "/" + subpage;
            }
        }

        if(slug == 'home' && lang !== 'en')
        {
            slug += "-" + lang;
        }
        
        axios
            .get(process.env.STRAPI_URL + '/api/pages/?filters[slug][$eq]=' + slug + '&populate=deep,4&locale=' + lang)
            .then((response) => {
                console.log(response.data.data[0].attributes);
                SetContent(response.data.data[0].attributes);
                SetContentId(response.data.data[0].id);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang, props.slug, page, subpage])

    return (
        <>
            {content && contentId && 
                <div>
                    <Content content={content.content} contentId={contentId} />
                </div>
            }
        </>
    );
}

export default Page;