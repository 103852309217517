import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import { motion } from "framer-motion";
import GetImageSize from "../../utilities/getimagesize/getimagesize";
import Markdown from "react-markdown";
import { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../elements/button/button";

function Slideshow(props: any) {

    const container = useRef(null);
    const [sliderWidth, SetSliderWidth] = useState<number>(0);
    const [slideWidth, SetSlideWidth] = useState<number>(0);
    const [slideIndex, SetSlideIndex] = useState<number>(0);

    useEffect(() =>
    {
        SetSlideWidth(container.current.offsetWidth);
        SetSliderWidth(props.content.slides.length * container.current.offsetWidth);
    }, [props, container])
    
    const Next = () =>
    {
        if(slideIndex < props.content.slides.length - 1) SetSlideIndex(slideIndex + 1);
    }

    const Previous = () =>
    {
        if(slideIndex > 0) SetSlideIndex(slideIndex - 1);
    }

    const GetColor = (index: number) =>
    {
        if(index == slideIndex) return 'bg-neutral-600';
        return 'bg-neutral-200';
    }

    return (
        <div className="hidden lg:block">
            <ContentWrapper>
                <div className="-mx-20 shadow-slide mt-24 h-[640] overflow-hidden" ref={container}>
                    <div className="h-[640] flex" style={{width: sliderWidth}}>
                        {props.content.slides.map((slide:any, index:number) =>
                            <Fragment key={'slide-' + index}>
                                {index == 0 &&
                                    <motion.div className="flex" style={{width: slideWidth}} animate={index == 0 ? {marginLeft: slideIndex * slideWidth * -1} : {}}>
                                        <div className="w-3/5 first-line:h-[640]">
                                            <img src={GetImageSize(slide.image, 'large')} className="block h-full w-full object-cover" />
                                        </div>
                                        <div className="w-2/5 h-[640] p-20">
                                            {slide.tag &&
                                                <div className="bg-yellow-500 uppercase w-fit text-white px-3 py-1 text-sm mb-8">{slide.tag}</div>
                                            }
                                            <h2 className="font-bold text-5xl mb-12 text-sic-blue">{slide.title}</h2>
                                            <div className="text-neutral-500 leading-relaxed">
                                                <Markdown>{slide.text}</Markdown>
                                            </div>
                                        </div>
                                    </motion.div>
                                }
                                {index == 1 &&
                                    <motion.div className="flex" style={{width: slideWidth}}>
                                        <div className="w-2/5 first-line:h-[640]">
                                            <img src={GetImageSize(slide.image, 'large')} className="block h-full w-full object-cover" />
                                        </div>
                                        <div className="w-3/5 h-[640] p-20">
                                            {slide.tag &&
                                                <div className="bg-yellow-500 uppercase w-fit text-white px-3 py-1 text-sm mb-8">{slide.tag}</div>
                                            }
                                            <h2 className="font-bold text-5xl mb-12 text-sic-blue">{slide.title}</h2>
                                            <div className="text-neutral-500 leading-relaxed">
                                                <Markdown>{slide.text}</Markdown>
                                            </div>
                                        </div>
                                    </motion.div>
                                }
                            </Fragment>
                        )}
                    </div>
                </div>
                <div className="mt-12 pb-36 flex">
                    <div className="" onClick={() => Previous()} style={slideIndex == 0 ? {opacity: 0.5} : {opacity: 1}}><Button label='Previous' color='grey' /></div>
                    <div className="flex-grow text-center">
                        {props.content.slides.map((slide:any, index:number) =>
                            <div key={'slide-dot-' + index} className={GetColor(index) + ' size-3 rounded-full inline-block mr-2'} />
                        )}
                    </div>
                    <div className="" onClick={() => Next()} style={slideIndex == props.content.slides.length - 1 ? {opacity: 0.5} : {opacity: 1}}><Button label='Next' color='grey' /></div>
                </div>
            </ContentWrapper>
        </div>
    );
}

export default Slideshow;