import { NavLink } from "react-router-dom";
import GetPath from "../../utilities/getpath/getpath";
import { useContext } from "react";
import { AppContext } from "../../../App";


function Button(props: any) {
    
    const { lang, productsSlug } = useContext(AppContext);

    const GetClassName = (color:string) => 
    {
        let classString = 'font-bold uppercase py-2 px-6 rounded-xl -mt-2 text-sm ';

        if(color == 'blue') classString += 'bg-sic-blue text-white';
        if(color == 'grey') classString += 'bg-neutral-200 text-neutral-600';
        if(color == 'white') classString += 'bg-white text-sic-blue';
        if(color == 'yellow' || !color) classString += 'bg-yellow-500 text-white';

        return classString;
    }

    const GetTo = (button:any) =>
    {
        if(button.page && button.page.data) return GetPath(lang, button.page.data.attributes.slug);
        if(button.product && button.product.data) return GetPath(lang, productsSlug, button.product.data.attributes.slug);
        if(button.url) return button.url;
    }

    return (
        <>
            {props.label &&
                <button className={GetClassName(props.color) + ' ' + props.className}>{props.label}</button>
            }
            {props.button &&
                <NavLink to={GetTo(props.button)} target={props.button.openInNewWindow ? '_blank' : '_self' } className={GetClassName(props.color) + ' ' + props.className}>{props.button.label}</NavLink>
            }
        </>
    );
}

export default Button;