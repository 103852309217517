import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import { motion } from "framer-motion";
import GetImageSize from "../../utilities/getimagesize/getimagesize";

function Gallery(props: any) {

    const GetPadding = () => {
        if(props.content.objectFit == 'Padded') return ' p-2';
    }

    const GetAspect = () => {
        if(props.content.aspectRatio == 'Photo 4:3') return ' aspect-4/3';
        if(props.content.aspectRatio == 'Video 16:9') return ' aspect-video';
        return ' aspect-square';
    }

    const GetFit = () => {
        if(props.content.objectFit == 'Cover') return ' object-cover';
        return ' object-contain';
    }

    const GetBackground = () => {
        if(props.content.background == 'White') return ' bg-white';
        return ' bg-neutral-100';
    }

    return (
        <>
            {props.content.images &&
                <ContentWrapper>
                    <div className="flex flex-wrap justify-center gap-6 mt-24 mb-20 mx-auto">
                        {props.content.images.data.map((image:any, index:number) => 
                            <motion.div
                                key={'gallery-image-' + index}
                                initial={{ y: 100, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20,
                                    delay: index * 0.1
                                }}
                                viewport={{once: true, amount: 0.25}}
                                className="w-2/5 sm:w-1/3 md:w-1/5 lg:w-1/7"
                            >
                                <img src={GetImageSize(image, 'xsmall', true)} className={"w-full mb-4" + GetBackground() + GetPadding() + GetAspect() + GetFit() } />
                            </motion.div>
                        )}
                    </div> 
                </ContentWrapper>
            }
        </>
    );
}

export default Gallery;