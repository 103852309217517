import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import GetImageSize from "../../utilities/getimagesize/getimagesize";
import Markdown from 'react-markdown';
import { NavLink } from 'react-router-dom';
import GetPath from '../../utilities/getpath/getpath';
import { useContext } from 'react';
import { AppContext } from '../../../App';

function ProductOverview(props: any) {

    const { productsSlug, lang } = useContext(AppContext);

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className='w-full py-32 bg-cover bg-center bg-neutral-100' style={props.content.background.data ? {backgroundImage: 'url('+ GetImageSize(props.content.background, 'large') + ')'} : {}}>
                    <ContentWrapper>
                        <div className="text-center">
                            <div className="text-xl mb-6">
                                {props.content.intro}
                            </div>
                            <div className="text-4xl lg:text-5xl font-bold mb-12">
                                {props.content.title}
                            </div>
                            {props.content.gallery &&
                                <div className='flex justify-center justify-items-center mb-12 gap-6'>
                                    {props.content.gallery.data && props.content.gallery.data.map((image:any, index:number) =>
                                        <img key={'gallery-image-' + index} src={GetImageSize(image, 'small', true)} className='block h-32 w-auto' />
                                    )}
                                </div>
                            }
                            <div className="max-w-4xl mx-auto mb-6">
                                <Markdown>{props.content.text}</Markdown>
                            </div>
                            {props.content.products.data.length > 0 &&
                                <div className="flex flex-wrap justify-center gap-4 pt-6 mx-auto">
                                    {props.content.products.data.map((product:any, index:number) =>
                                        <motion.div
                                            key={'product-category-' + index}
                                            initial={{ y: 100, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 260,
                                                damping: 20,
                                                delay: index * 0.1
                                            }}
                                            viewport={{once: true, amount: 0.25}}
                                            className='w-2/5 sm:w-1/3 md:w-1/5 lg:w-1/8'
                                        >
                                            <NavLink to={GetPath(lang, productsSlug, product.attributes.slug)}>
                                                <img src={GetImageSize(product.attributes.image, 'xsmall')} className="w-full aspect-4/3 p-2 bg-white mb-4 object-contain" />
                                                <span dangerouslySetInnerHTML={{__html: product.attributes.name}} />
                                            </NavLink>
                                        </motion.div>
                                    )}
                                </div>
                            }     
                            {props.content.button &&
                                <div className='mt-16'>
                                    <Button button={props.content.button} color='blue' />
                                </div> 
                            }
                        </div>
                    </ContentWrapper> 
                </div>
            </motion.div>
        </>
    );
}

export default ProductOverview;