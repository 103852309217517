import { useContext } from "react";
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import Form from "../../form/form";
import { ContentContext } from "../content";

function FormBlock(props: any) {

    const { contentId } = useContext(ContentContext);

    return (
        <div className="bg-neutral-100 w-full py-20"> 
                {props.content.form && props.content.form.data &&   
                    <ContentWrapper>
                        <Form 
                            type='Full'
                            formId={props.content.form.data.id} 
                            pageId={contentId} 
                            intro={props.content.form.data.attributes.intro}
                            title={props.content.form.data.attributes.title}
                            text={props.content.form.data.attributes.text}
                            fields={props.content.form.data.attributes.fields}
                            buttonLabel={props.content.form.data.attributes.buttonLabel}
                            response={props.content.form.data.attributes.response}
                        />
                    </ContentWrapper>
                }
        </div>
    );

}

export default FormBlock;