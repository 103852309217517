
function ContentWrapper({children}) {
    
    return (
        <div className="max-w-screen-2xl px-12 sm:px-16 lg:px-24 mx-auto">
            {children}
        </div>
    );
}

export default ContentWrapper;