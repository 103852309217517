import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import Markdown from 'react-markdown';
import '@google/model-viewer';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
const background = new URL('../../../img/background-bluegradient.png', import.meta.url);
const environment = new URL('../../../assets/studio_small_09_1k.hdr', import.meta.url);
// const model = new URL('../../../assets/model/RubberMetal.glb', import.meta.url);

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string,
      poster?: string,
      'camera-controls'?: boolean,
      'environment-image'?: string,
      'shadow-intensity'?: number,
      'auto-rotate'?: boolean,
      'auto-rotate-delay'?: number,
      'rotation-per-second'?: string,
      'interaction-prompt'?: string,
      'interaction-prompt-style'?: string,
      'interaction-prompt-threshold'?: number,
      'camera-orbit'?: string,
      'disable-zoom'?: boolean,
      'disable-tap'?: boolean,
      'field-of-view'?: string,
      'min-field-of-view'?: string,
      'tone-mapping'?: string,
      'exposure'?: number,
      children?: any,
      style?: any
    }
  }
}

function Feature3D(props: any) {

    const GetFieldOfView = () =>
    {
        if(props.content.fieldOfView !== 0) return props.content.fieldOfView + 'deg';
        return 'auto';
    }

    return (
        <>
            <div className='w-full py-12 bg-cover bg-repeat-y' style={{backgroundImage: 'url(' + background.pathname + ')'}}>
                <ContentWrapper>
                    <div className='lg:float-left lg:w-2/5 min-h-10 lg:pr-6'>
                        <model-viewer 
                            src={process.env.STRAPI_URL + props.content.model.data.attributes.url} 
                            camera-controls={false} 
                            environment-image={environment.pathname} 
                            auto-rotate 
                            auto-rotate-delay={0}
                            rotation-per-second='30deg'
                            shadow-intensity={0.5}
                            interaction-prompt='auto'
                            interaction-prompt-style='wiggle'
                            camera-orbit='45deg'
                            field-of-view={GetFieldOfView()}
                            disable-zoom
                            disable-tap
                            style={{width: 400, height: 400}}
                        >
                            <div slot='progress-bar'></div>
                        </model-viewer>
                    </div>
                    <div className='lg:float-right lg:w-3/5 text-white py-12 lg:pl-6'>
                        <motion.div className="text-xl mb-2" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}}>
                            {props.content.intro}
                        </motion.div>
                        <motion.h2 className="text-4xl lg:text-5xl font-bold mb-6 leading-tight" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.2}}>
                            {props.content.title}
                        </motion.h2>
                        <motion.div className="mb-12" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.4}}>
                            <Markdown>{props.content.text}</Markdown> 
                        </motion.div>
                        {props.content.button &&
                            <motion.div className="" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.6}}>
                                <Button button={props.content.button} />
                            </motion.div>
                        }
                    </div>
                    <div className='clear-both' />
                </ContentWrapper>
                </div>
        </>
    );
}

export default Feature3D;