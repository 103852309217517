import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavLink, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import GetPath from "../../../utilities/getpath/getpath";
import slugify from "slugify";

function FAQ(props: any) {

    const { lang, productsSlug } = useContext(AppContext);
    const [question, SetQuestion] = useState<number>(-1);
    const {productSlug} = useParams();

    const GetHeight = (index: number) =>
    {
        if(index == question) return 'auto';
        return 0;
    }

    const SelectQuestion = (index: number) =>
    {
        if(question == index)
            SetQuestion(-1);
        else
            SetQuestion(index);
    }

    return (
        <>
            <div className="text-l mb-4">{props.intro}</div>
            <div className="text-5xl font-bold mb-8">{props.title}</div>
            {props.questions.map((question: any, index: number) =>
                <NavLink to={GetPath(lang, productsSlug, productSlug) + '/faq/' + slugify(question.inquiry, {lower: true, strict: true})} key={'question-' + index} onClick={(e) => {e.preventDefault(); SelectQuestion(index)}} className="block border-b border-neutral-200 pt-4 cursor-pointer">
                    <span className="mr-6 text-neutral-500">{index + 1}</span>
                    <span className="text-sic-blue">{question.inquiry}</span>
                    <div className="pb-4">
                        <motion.div className="text-neutral-500 leading-relaxed overflow-hidden" animate={{height: GetHeight(index)}}>
                            <div className="pt-4 pb-4">{question.answer}</div>
                        </motion.div>
                    </div>
                </NavLink>
            )}
        </>
    );
}

export default FAQ;