import { motion } from 'framer-motion';
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import GetImageSize from "../../utilities/getimagesize/getimagesize";
import Markdown from 'react-markdown';
const background = new URL('../../../img/background-bluegradient.png', import.meta.url);

function InfoCards(props: any) {

    const textColor = props.content.theme == 'Blue' ? ' text-white' : ' text-neutral-500';
    const titleColor = props.content.theme == 'Blue' ? ' text-white' : ' text-black';
    const bgStyle = props.content.theme == 'Blue' ? {backgroundImage: 'url(' + background.pathname + ')'} : {}
    const border = props.content.theme == 'Light' ? ' border border-neutral-200' : '';

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} style={bgStyle}>
                <div className={'w-full py-20 bg-cover bg-repeat-y' + textColor}>
                    <ContentWrapper>
                        <div className="text-center">
                            <div className={"text-xl mb-4" + titleColor}>
                                {props.content.intro}
                            </div>
                            <div className={"text-4xl lg:text-5xl font-bold mb-12" + titleColor}>
                                {props.content.title}
                            </div>
                            <div className="max-w-4xl mx-auto mb-6">
                                <Markdown>{props.content.text}</Markdown>
                            </div>

                            {props.content.gallery.data && props.content.gallery.data.length > 0 &&
                                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 justify-center items-center gap-6 mt-24 mb-20 mx-auto">
                                    {props.content.gallery.data.map((image:any, index:number) => 
                                        <motion.div
                                            key={'gallery-image-' + index}
                                            initial={{ y: 100, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 260,
                                                damping: 20,
                                                delay: index * 0.1
                                            }}
                                            viewport={{once: true, amount: 0.25}}
                                        >
                                            <img src={GetImageSize(image, 'xsmall', true)} className="w-full aspect-square p-2 bg-neutral-100 mb-4 object-contain" />
                                        </motion.div>
                                    )}
                                </div> 
                            }

                            {props.content.cards.length > 0 &&
                                <div className='lg:flex lg:gap-12 flex-wrap text-neutral-500 text-left mt-20'>
                                    {props.content.cards.map((card:any, index:number) =>
                                        <div key={'card-' + index} className={'w-full lg:w-[calc(50%-1.5rem)] mb-12 lg:mb-0 bg-white px-10 py-12 rounded-2xl' + border}>
                                            <div className={"text-l mb-2 text-black"}>
                                                {card.intro}
                                            </div>
                                            <div className={"text-3xl font-bold mb-8 text-black"}>
                                                {card.title}
                                            </div>
                                            <div className="">
                                                <Markdown>{card.text}</Markdown>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </ContentWrapper> 
                </div>
            </motion.div>
        </>
    );
}

export default InfoCards;