import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import GetImageSize from '../../utilities/getimagesize/getimagesize';
import Markdown from 'react-markdown';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';

function Banner(props: any) {

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                    <div className='w-full lg:flex bg-white'>
                        <div className='hidden lg:block lg:w-2/5'>
                            <img src={GetImageSize(props.content.image, 'large')} className='block w-auto h-[110%] mt-[-5%] z-50' />
                        </div>
                        <div className="lg:w-3/5 text-black">
                            <div className='py-32 w-full max-w-[calc(1440px-40%)] pl-12 lg:pl-0 pr-12'>
                                <div className='text-xl mb-4'>{props.content.intro}</div>
                                <h2 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{props.content.title}</h2>
                                <div className='mb-12 leading-relaxed'><Markdown>{props.content.text}</Markdown></div>
                                {props.content.buttonLabel &&
                                    <Button label={props.content.buttonLabel} color='blue' />
                                }
                            </div>
                        </div>
                    </div>
            </motion.div>
        </>
    );
}

export default Banner;