import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import Markdown from 'react-markdown';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
import GetImageSize from '../../utilities/getimagesize/getimagesize';

function Columns(props: any) {

    const GetBasis = () =>
    {
        if(props.content.columnAmount > 0)
        {
            if(props.content.columnAmount == 2) return 'basis-[calc((100%-5rem)/2)]';
            if(props.content.columnAmount == 3) return 'basis-[calc((100%-10rem)/3)]';
            if(props.content.columnAmount == 4) return 'basis-[calc((100%-15rem)/4)]';
            if(props.content.columnAmount == 5) return 'basis-[calc((100%-20rem)/5)]';
        }

        return 'flex-1';
    } 

    return (
        <>
            <ContentWrapper>
                <div className='my-20'>
                    {props.content.title &&
                        <h2 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{props.content.title}</h2>
                    }
                    <div className='lg:flex lg:flex-wrap lg:gap-20'>
                        {props.content.items.map((column:any, index:number) =>
                            <motion.div key={'column-' + index} className={"mb-6 " + GetBasis()} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{delay: index * 0.2}} viewport={{amount: 0.15}}>
                                {column.intro && 
                                    <div className='text-l mb-2'>{column.intro}</div>
                                }
                                {column.title &&
                                    <h2 className='font-bold text-2xl md:text-3xl mb-8'>{column.title}</h2>
                                }
                                {column.image && column.image.data &&
                                    <img src={GetImageSize(column.image, 'medium')} className='w-full h-auto mb-8' />
                                }
                                <div className='text-neutral-500 leading-relaxed text-justify'><Markdown>{column.text}</Markdown></div>
                                {column.buttonLabel && 
                                    <div className='mt-12'><Button color='blue' button={{label: column.buttonLabel, url:column.buttonLink, openInNewWindow: true}} /></div>
                                }
                            </motion.div>
                        )}
                    </div>
                </div>
            </ContentWrapper>
        </>
    );
}

export default Columns;