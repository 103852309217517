import { motion } from "framer-motion";
import Header from "./header/header";
import CallToActon from "./calltoaction/calltoaction";
import ProductOverview from "./productoverview/productoverview";
import IndustryOverview from "./industryoverview/industryoverview";
import Text from "./text/text";
import Feature3D from "./feature3d/feature3d";
import Gallery from "./gallery/gallery";
import Scroller from "./scroller/scroller";
import Slideshow from "./slideshow/slideshow";
import Columns from "./columns/columns";
import AnchorMenu from "./anchormenu/anchormenu";
import { useRef, createContext } from "react";
import Story from "./story/story";
import Cards from "./cards/cards";
import FormBlock from "./formblock/formblock";
import InfoCards from "./infocards/infocards";
import ProductVideo from "./productvideo/productvideo";
import Banner from "./banner/banner";
import Meta from "./meta/meta";

export const ContentContext = createContext<any>(null);

function Content(props:any) {

    const contentRefs = useRef([]);

    const IsAllowedByFilter = (component:string) =>
    {
        // If there are no filters > everything is allowed
        if(!props.filter || props.filter.length ==0) return true;

        // If filtered > only allow included components
        return props.filter.includes(component);
    }

    return (
        <ContentContext.Provider value={{ contentRefs: contentRefs, contentId: props.contentId }}>
            {props.content &&
                <div>
                    {props.content.map((block:any, index:number) => 
                        <motion.div  ref={ref => { contentRefs.current['content-' + index] = ref }} key={'content-' + index} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                            {IsAllowedByFilter(block.__component) &&
                                <>
                                    {block.__component == 'page.header' && 
                                        <Header content={block} />
                                    }
                                    {block.__component == 'page.call-to-action' &&
                                        <CallToActon content={block} />
                                    }
                                    {block.__component == 'page.product-overview' &&
                                        <ProductOverview content={block} />
                                    }
                                    {block.__component == 'page.industry-overview' &&
                                        <IndustryOverview content={block} />
                                    }
                                    {block.__component == 'page.text' &&
                                        <Text content={block} />
                                    }
                                    {block.__component == 'page.3-d-feature' &&
                                        <Feature3D content={block} />
                                    }
                                    {block.__component == 'page.gallery' &&
                                        <Gallery content={block} />
                                    }
                                    {block.__component == 'page.scroller' &&
                                        <Scroller content={block} />
                                    }
                                    {block.__component == 'page.slideshow' &&
                                        <Slideshow content={block} />
                                    }
                                    {block.__component == 'page.columns' &&
                                        <Columns content={block} />
                                    }
                                    {block.__component == 'page.menu' &&
                                        <AnchorMenu content={block} />
                                    }
                                    {block.__component == 'page.story' &&
                                        <Story content={block} />
                                    }
                                    {block.__component == 'page.cards' && 
                                        <Cards content={block} />
                                    }
                                    {block.__component == 'page.form' && 
                                        <FormBlock content={block} />
                                    }
                                    {block.__component == 'page.info-cards' && 
                                        <InfoCards content={block} />
                                    }
                                    {block.__component == 'page.product-video' && 
                                        <ProductVideo content={block} />
                                    }
                                    {block.__component == 'page.banner' &&
                                        <Banner content={block} />
                                    }
                                    {block.__component == 'page.meta' &&
                                        <Meta content={block} />
                                    }
                                </>
                            }
                        </motion.div>
                    )}
                </div>
            }
        </ContentContext.Provider>
    );
}

export default Content;