
function GetImageSize(image: any, size: string, fromArray:boolean = false)
{
    if(fromArray)
    {
        if((size == 'xsmall') && image.attributes.formats.xsmall) return process.env.STRAPI_URL + image.attributes.formats.xsmall.url;
        if((size == 'xsmall' || size == 'small') && image.attributes.formats.small) return process.env.STRAPI_URL + image.attributes.formats.small.url;
        if((size == 'xsmall' || size == 'small' || size == 'medium') && image.attributes.formats.medium) return process.env.STRAPI_URL + image.attributes.formats.medium.url;
        if((size == 'xsmall' || size == 'small' || size == 'medium' || size == 'large') && image.attributes.formats.large) return process.env.STRAPI_URL + image.attributes.formats.large.url;
        return process.env.STRAPI_URL + image.attributes.url;
    }

    if((size == 'xsmall') && image.data.attributes.formats.xsmall) return process.env.STRAPI_URL + image.data.attributes.formats.xsmall.url;
    if((size == 'xsmall' || size == 'small') && image.data.attributes.formats.small) return process.env.STRAPI_URL + image.data.attributes.formats.small.url;
    if((size == 'xsmall' || size == 'small' || size == 'medium') && image.data.attributes.formats.medium) return process.env.STRAPI_URL + image.data.attributes.formats.medium.url;
    if((size == 'xsmall' || size == 'small' || size == 'medium' || size == 'large') && image.data.attributes.formats.large) return process.env.STRAPI_URL + image.data.attributes.formats.large.url;
    return process.env.STRAPI_URL + image.data.attributes.url;
}

export default GetImageSize;