import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirector(props: any) {

    let navigate = useNavigate();

    useEffect(() => 
    {

        // Check for old news urls
        const cleanPath = location.pathname.replace(process.env.BASE_PATH, '/');

        console.log('PATH: ' + cleanPath);

        if(cleanPath.startsWith('/company/news/'))
        {
            let newPath = cleanPath.replace('/company/news/', '/news/');
            navigate(newPath);
        }

        if(cleanPath.startsWith('/hu/company/news/'))
        {
            let newPath = cleanPath.replace('/hu/company/news/', '/hu/hirek/');
            navigate(newPath);
        }

        if(cleanPath.startsWith('/de/company/news/'))
        {
            let newPath = cleanPath.replace('/de/company/news/', '/de/nachrichten/');
            navigate(newPath);
        }

    }, []);
    

    return (<></>);
}

export default Redirector;