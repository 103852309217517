function Socials(props: any) {
    
    // Icons from https://tabler.io/icons/
    // Used: IconBrandFacebook, IconBrandLinkedin, IconBrandYoutube, IconBackground
    // Other: IconBrandInstagram, IconWorld

    return (
        <>
            <a href="https://www.linkedin.com/company/sic-hungary-rubber-manifacturing" target="_blank" rel="noreferrer" className='inline-block bg-yellow-500 rounded-full size-8 aspect-square mr-2'>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="white"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-linkedin mx-auto mt-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M8 11l0 5" /><path d="M8 8l0 .01" /><path d="M12 16l0 -5" /><path d="M16 16v-3a2 2 0 0 0 -4 0" /></svg>
            </a>
            <a href="https://www.facebook.com/sicrubbermanufacturing/" target="_blank" rel="noreferrer" className='inline-block bg-yellow-500 rounded-full size-8 aspect-square mr-2'>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="white"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook mx-auto mt-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
            </a>
            <a href="https://www.youtube.com/channel/UCykPlI8-C1-Xvp3JsO6OpGQ" target="_blank" rel="noreferrer" className='inline-block bg-yellow-500 rounded-full size-8 aspect-square mr-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-youtube mx-auto mt-1"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" /><path d="M10 9l5 3l-5 3z" /></svg>
            </a>
            <a href="https://ipariexpo.hu/cegek/sic-kft/" target="_blank" rel="noreferrer" className='inline-block bg-yellow-500 rounded-full size-8 aspect-square mr-2'>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="white"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-background mx-auto mt-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 8l4 -4" /><path d="M14 4l-10 10" /><path d="M4 20l16 -16" /><path d="M20 10l-10 10" /><path d="M20 16l-4 4" /></svg>
            </a>
        </>
    );
}

export default Socials;