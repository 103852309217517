
function GetPath(language:string, page:string, subpage:string = null)
{
    let path = process.env.BASE_PATH;
    
    if(language !== 'en')
    {
        path = process.env.BASE_PATH + language;
        if(page) path += '/' + page;
    }
    else
    {
        if(page) path += page;
    }
    
    if(subpage) path += '/' + subpage;

    return path;
}

export default GetPath;