import { motion } from 'framer-motion';
import Markdown from 'react-markdown';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
import GetImageSize from '../../utilities/getimagesize/getimagesize';
import Socials from '../../elements/socials/socials';

function Cards(props: any) {

    const GetBackgroundStyle = () => 
    {
        if(props.content.background && props.content.background.data) return {backgroundImage: 'url('+ GetImageSize(props.content.background, 'large') + ')'}
    }

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className='py-20 bg-neutral-100 text-center bg-cover bg-center' style={GetBackgroundStyle()}>
                    <ContentWrapper>
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 w-fit mx-auto'>
                            {props.content.items.map((card:any, index:number) =>
                                <div key={'card-' + index} className='bg-white px-8 pb-12 pt-4'>
                                    {card.icon && card.icon.data && 
                                        <img src={GetImageSize(card.icon, 'small')} className='pt-8 max-w-20 inline-block' />
                                    }
                                    {card.title &&
                                        <h3 className='font-bold pt-8'>{card.title}</h3>
                                    }
                                    {card.text &&
                                        <div className='pt-8 text-neutral-500'>
                                            <Markdown>{card.text}</Markdown>
                                        </div>
                                    }
                                    {card.highlight &&
                                        <h4 className='text-sic-blue pt-8 text-xl'>{card.highlight}</h4>
                                    }
                                    {card.extra == 'Socials' &&
                                        <div className='pt-4'>
                                            <Socials />
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </ContentWrapper>
                </div>
            </motion.div>
        </>
    );
}

export default Cards;