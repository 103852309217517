import { motion } from 'framer-motion';
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import Dots from '../../elements/dots/dots';
import GetImageSize from '../../utilities/getimagesize/getimagesize';
import Markdown from 'react-markdown';
import { NavLink } from 'react-router-dom';
import GetPath from '../../utilities/getpath/getpath';
import { useContext } from 'react';
import { AppContext } from '../../../App';

function IndustryOverview(props: any) {

    const {lang, industriesSlug} = useContext(AppContext);

    const backgroundColor = props.content.theme == 'Dark' ? ' bg-neutral-900' : ' bg-neutral-100';
    const textColor = props.content.theme == 'Dark' ? ' text-white' : ' text-neutral-500';
    const titleColor = props.content.theme == 'Dark' ? ' text-white' : ' text-black';

    const GetGridCols = () =>
    {
        if(props.content.industries.data && props.content.industries.data.length == 1) return '';
        if(props.content.industries.data && props.content.industries.data.length == 2) return 'sm:grid-cols-2';
        if(props.content.industries.data && props.content.industries.data.length == 3) return 'sm:grid-cols-2 lg:grid-cols-3';
        if(props.content.industries.data && props.content.industries.data.length == 4) return 'sm:grid-cols-2 lg:grid-cols-4';
        if(props.content.industries.data && props.content.industries.data.length == 5) return 'sm:grid-cols-2 lg:grid-cols-3';
        if(props.content.industries.data && props.content.industries.data.length == 6) return 'sm:grid-cols-3 lg:grid-cols-3';
        if(props.content.industries.data && props.content.industries.data.length == 7) return 'sm:grid-cols-2 lg:grid-cols-4';
        if(props.content.industries.data && props.content.industries.data.length == 8) return 'sm:grid-cols-2 lg:grid-cols-4';
    }

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className={'w-full py-20 bg-cover bg-center' + backgroundColor + textColor} style={props.content.background.data ? {backgroundImage: 'url('+ GetImageSize(props.content.background, 'large') + ')'} : {}}>
                    <ContentWrapper>
                        <div className="text-center">
                            {props.content.intro &&
                                <div className={"text-xl mb-4" + titleColor}>
                                    {props.content.intro}
                                </div>
                            }
                            {props.content.title &&
                                <div className={"text-4xl lg:text-5xl font-bold mb-12" + titleColor}>
                                    {props.content.title}
                                </div>
                            }
                            {props.content.text && 
                                <>
                                    <div className="mb-6">
                                        <Dots color='blue' />
                                    </div>
                                    <div className="max-w-3xl mx-auto mb-6">
                                        <Markdown>{props.content.text}</Markdown>
                                    </div>
                                </>
                            }
                            {props.content.industries.data && 
                                <div className={"grid grid-cols-1 justify-center max-w-5xl gap-12 pt-6 mb-16 mx-auto " + GetGridCols()}>
                                    {props.content.industries.data.map((industry:any, index:number) =>
                                        <div key={'service-' + index}>
                                            <motion.div
                                                initial={{ y: 100, opacity: 0 }}
                                                whileInView={{ y: 0, opacity: 1 }}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: index * 0.1
                                                }}
                                                viewport={{once: true, amount: 0.25}}
                                            >
                                                <NavLink to={GetPath(lang, industriesSlug, industry.attributes.slug)} className='text-center'>
                                                    <img src={GetImageSize(industry.attributes.icon, 'xsmall')} className="inline-block size-24 aspect-square p-2 mb-4 object-contain" />
                                                    <div className='font-bold text-xl'>{industry.attributes.title}</div>
                                                    <div className="my-3"><Dots color='blue' /></div>
                                                    <div className='text-sm mt-4 max-w-60 mx-auto'><Markdown>{industry.attributes.intro}</Markdown></div>
                                                </NavLink>
                                            </motion.div>
                                        </div>
                                    )}
                                </div> 
                            }    
                        </div>
                    </ContentWrapper> 
                </div>
            </motion.div>
        </>
    );
}

export default IndustryOverview;