import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import { ContentContext } from "../content";

function AnchorMenu(props:any) 
{
    const {contentRefs} = useContext(ContentContext);
    const [active, SetActive] = useState<number>(0)

    useEffect(() =>
    {
        if(props.content.items.length > 0) SetActive(props.content.items[0].index);
    }, [])

    const IsActive = (index: number) =>
    {
        if(active == index) return true;
        return false;
    }

    const Click = (index: number) =>
    {
        SetActive(index);
        contentRefs.current['content-' + index].scrollIntoView({behavior: "smooth"});
    }

    return (
        <>
            <ContentWrapper>
                {props.content.items &&
                    <div className="pt-20 text-center">
                        {props.content.items.map((menuItem: any, index:number) =>
                            <span className='block lg:inline-block mb-4 lg:mb-0 mx-2 bg-neutral-100 py-1 px-8 rounded-lg cursor-pointer'>
                                <a key={'menu-' + index} onClick={() => Click(menuItem.index)}>{menuItem.label}</a>
                            </span>
                        )}
                    </div>
                }
            </ContentWrapper>
        </>
    );
}

export default AnchorMenu;