import { motion } from 'framer-motion';
import Button from '../../elements/button/button';
import GetImageSize from '../../utilities/getimagesize/getimagesize';
import Markdown from "marked-react";
import { marked } from 'marked';
const blueGradient = new URL('../../../img/background-bluegradient.png', import.meta.url);

function Text(props: any) {

    const BackgroundStyle = () =>
    {
        let style = {backgroundImage: ''};
        if(props.content.theme == 'Blue') style.backgroundImage = 'url(' + blueGradient.pathname + ')';
        return style;
    }

    const TextColor = () =>
    {
        let color = 'text-neutral-500';
        if(props.content.theme == 'Blue') color = 'text-white';
        return color;
    }

    const TitleColor = () =>
    {
        let color = 'text-black';
        if(props.content.theme == 'Blue') color = 'text-white';
        return color;
    }

    const GalleryClasses = () =>
    {
        let classes = 'float-none lg:float-right px-12 lg:pr-0 lg:pl-24 pt-20 pb-0 lg:py-20 ';
        if(props.content.type == 'Left') classes = 'float-none lg:float-left px-12 lg:pl-0 lg:pr-24 pt-0 pb-20 lg:py-20 ';
        return classes;
    }

    const BlockText = (side: string) =>
    {
        let float = 'left';
        if(side == 'Left') float = 'right';

        let width = 'lg:w-1/2 ';
        let overrides = '';
        if(!props.content.image.data || props.content.image.data.length < 0)
        {
            width = '';
            overrides = ' lg:float-none lg:max-w-screen-2xl max-w-screen-2xl px-12 sm:px-16 lg:px-24 mx-auto';
        }


        return ( 
            <div className={"w-full " + width + TitleColor()}>
                <div className={'max-w-none lg:max-w-screen-md w-full py-20 px-12 sm:px-16 lg:px-24 lg:float-' + float + overrides}>
                    <div className='text-xl mb-4'>{props.content.intro}</div>
                    <h2 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{props.content.title}</h2>
                    <div className={'mb-12 leading-relaxed ' + TextColor()}><div dangerouslySetInnerHTML={{ __html: marked.parse(props.content.text).toString() }} /></div>
                    {props.content.button &&
                        <Button button={props.content.button} color={props.content.theme == 'Blue'? 'white' : 'blue'} />
                    }
                </div>
            </div>
        );
    }

    const BlockImage = () =>
    {
        if(props.content.image.data)
        {
            if(props.content.image.data.length == 1)
            {
                return (<div className={'bg-cover w-full lg:w-1/2 bg-center aspect-4/3 lg:aspect-auto'} style={{backgroundImage: 'url('+ GetImageSize(props.content.image.data[0], 'medium', true) + ')'}} />);
            }

            if(props.content.image.data.length > 1)
            {
                return(
                    <div className='w-full lg:w-1/2'>
                        <div className={'max-w-none lg:max-w-screen-md grid grid-cols-1 sm:grid-cols-2 gap-4 ' + GalleryClasses()}>
                            {props.content.image.data.map((image:any, index:number) =>
                                <img className='block w-full object-cover aspect-4/3' src={GetImageSize(image, 'small', true)} />
                            )}
                        </div>
                    </div>
                )
            }
        }
    }

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className='w-full flex-none lg:flex bg-neutral-100 bg-cover bg-repeat-y' style={BackgroundStyle()}>
                    {props.content.type == 'Right' &&
                        BlockImage()
                    }                   
                    {BlockText(props.content.type)}
                    {props.content.type == 'Left' &&
                        BlockImage()
                    }
                </div>
            </motion.div>
        </>
    );
}

export default Text;