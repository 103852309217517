import { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { AppContext } from "../../../App";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Content from "../../content/content";
import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import FAQ from "./faq/faq";
import Form from "../../form/form";
import Markdown from "react-markdown";
import slugify from "slugify";
import Button from "../../elements/button/button";
import GetPath from "../../utilities/getpath/getpath";

function Product(props:any) {

    const { lang, productsSlug } = useContext(AppContext); 
    const [productMain, SetProductMain] = useState<any>(null);
    const [product, SetProduct] = useState<any>(null);
    const [productId, SetProductId] = useState<any>(null);
    const {productSlug} = useParams();
    const {question} = useParams();

    useEffect(() => {  
        axios
            .get(process.env.STRAPI_URL + '/api/products?filters[slug][$eq]=' + productSlug + '&populate=deep,4&locale=' + lang)
            .then((response) => {
                console.log(response.data.data[0].attributes);
                SetProduct(response.data.data[0].attributes);
                SetProductId(response.data.data[0].id);
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(process.env.STRAPI_URL + '/api/pages?filters[slug][$eq]=' + productsSlug + '&populate=deep,4&locale=' + lang)
            .then((response) => {
                console.log(response.data.data[0].attributes);
                SetProductMain(response.data.data[0].attributes);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [lang, productSlug])

    const GetQuestionFromSlug = (slug:string) =>
    {
        var inquiry = slug;

        product.questions.forEach((question:any) => {
            if(slugify(question.inquiry, {lower: true, strict: true}) == slug)
            {
                inquiry = question.inquiry;
            }
        });

        return inquiry;
    }

    const GetAnswerFromSlug = (slug:string) =>
    {
        var answer = <>{slug}</>;

        product.questions.forEach((question:any) => {
            if(slugify(question.inquiry, {lower: true, strict: true}) == slug)
            {
                answer = <Markdown>{question.answer}</Markdown>
            }
        });

        return answer;
    }

    return (
        <>
            {product && productMain && productId && 
                <div>
                    <Content content={product.content} contentId={productId} filter={question ? ['page.3-d-feature'] : null} />
                    {product.questions && product.questions.length > 0 &&
                        <div className="py-20">
                            <ContentWrapper>
                                <div className="flex flex-wrap gap-20">
                                    <div className="flex-1 min-w-96">
                                        {question &&
                                            <>
                                                <div className="text-l mb-4">{product.faqTitle}</div>
                                                <div className="text-5xl font-bold mb-8">{GetQuestionFromSlug(question)}</div>
                                                <div className="text-neutral-500 leading-relaxed overflow-hidden mb-8">{GetAnswerFromSlug(question)}</div>
                                                <Button button={{url: GetPath(lang, productsSlug, product.slug), label: product.faqMoreButton}} color='blue' />
                                            </>
                                        }
                                        {!question &&
                                            <FAQ questions={product.questions} intro={product.faqIntro} title={product.faqTitle} />
                                        }
                                    </div>
                                    {product.form.data && productId && 
                                        <div className="flex-1">
                                            <Form 
                                                type='Small'
                                                formId={product.form.data.id} 
                                                productId={productId} 
                                                intro={product.form.data.attributes.intro}
                                                title={product.form.data.attributes.title}
                                                text={product.form.data.attributes.text}
                                                fields={product.form.data.attributes.fields}
                                                buttonLabel={product.form.data.attributes.buttonLabel}
                                                response={product.form.data.attributes.response}
                                                pageTitle={product.name}
                                            />
                                        </div>
                                    }
                                </div>
                            </ContentWrapper>
                        </div>
                    }
                    <Content content={productMain.content} />
                </div>
            }
        </>
    );
}

export default Product;