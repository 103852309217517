import ContentWrapper from "../../elements/contentwrapper/contentwrapper";
import { motion, useScroll, useTransform } from "framer-motion";
import GetImageSize from "../../utilities/getimagesize/getimagesize";
import Button from "../../elements/button/button";

function Header(props: any) {
    
    const { scrollYProgress } = useScroll();
    const scrollYValue = useTransform(() => scrollYProgress.get() * 300 - 192); // Needs to be based on screen width?

    const GetFloatClass = () =>
    {
        if(props.content.type == 'Right') return 'float-right';
    }

    return (
        <>
            {props.content &&
                <motion.div className="w-full h-80 lg:h-96 bg-slate-600 bg-cover bg-center text-white" style={{backgroundImage: 'url('+ GetImageSize(props.content.background, 'large') + ')'}}>{ /* }, backgroundPositionY: scrollYValue }}> */}
                    <ContentWrapper>
                        <div className={"pt-16 md:pt-20 sm:w-full md:w-3/4 lg:w-2/3 xl:w-1/2 " + GetFloatClass()}>
                            {props.content.intro &&
                                <motion.div className="text-xl mb-2" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}}>
                                    {props.content.intro}
                                </motion.div>
                            }
                            <motion.div className="" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.2}}>
                                <h1 className="
                                    font-bold block w-full max-w-3xl 
                                    text-3xl sm:text-4xl lg:text-5xl 
                                    leading-tight sm:leading-tight lg:leading-tight 
                                    sm:whitespace-pre-wrap"
                                >
                                    {props.content.title}    
                                </h1>
                            </motion.div>
                            <motion.div className="pt-5" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.4}}>
                                {props.content.subtitle}    
                            </motion.div>
                            {props.content.button &&
                                <motion.div className="mt-8" initial={{marginLeft: 100, marginRight: -100, opacity: 0}} whileInView={{marginLeft: 0, marginRight:0, opacity: 1}} transition={{delay: 0.6}}>
                                    <Button button={props.content.button} />
                                </motion.div>
                            }
                        </div>
                    </ContentWrapper>
                </motion.div>
            }
        </>
    );
}

export default Header;