import { motion } from 'framer-motion';
import Markdown from 'react-markdown';
import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
import GetImageSize from '../../utilities/getimagesize/getimagesize';

function Story(props: any) {

    return (
        <>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <div className='py-20 bg-neutral-100 text-center'>
                    <ContentWrapper>
                        {props.content.intro &&
                            <div className='text-xl mb-4'>{props.content.intro}</div>
                        }
                        {props.content.title &&
                            <h2 className='font-bold text-3xl md:text-4xl lg:text-5xl mb-12'>{props.content.title}</h2>
                        }
                        {props.content.text &&
                            <div className='mb-12 leading-relaxed w-full max-w-4xl mx-auto'><Markdown>{props.content.text}</Markdown></div>
                        }
                        {props.content.gallery && props.content.gallery.data && props.content.gallery.data.length > 0 && 
                            <div className='flex gap-4 flex-wrap items-center justify-center w-full'>
                                {props.content.gallery.data.map((image:any, index:number) =>
                                    <img key={'story-gallery-' + index} className='block w-full sm:w-[calc(50%-0.5rem)] md:w-[calc(25%-0.75rem)] aspect-square' src={GetImageSize(image, 'small', true)} />
                                )}
                            </div>
                        } 
                        {props.content.blocks && props.content.blocks.length > 0 &&
                            <>
                                {props.content.blocks.map((block:any, index:number) =>
                                    <div key={'story-block-' + index} className='mt-20 bg-white p-8 lg:flex lg:gap-6'>
                                        <div className='w-full lg:w-2/6 mb-8 lg:mb-0'>
                                            <img src={GetImageSize(block.image.data[0], 'medium', true)} className='w-full' />
                                        </div>
                                        <div className='w-full lg:w-4/6 text-left leading-relaxed lg:p-6'>
                                            <Markdown>{block.text}</Markdown>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </ContentWrapper>
                </div>
            </motion.div>
        </>
    );
}

export default Story;