import ContentWrapper from '../../elements/contentwrapper/contentwrapper';
import { motion, useScroll } from 'framer-motion';
import { WheelEvent, useCallback, useContext, useRef, useState } from 'react';
import { AppContext } from '../../../App';
import { useEffect } from 'react';
import Markdown from 'react-markdown';
import GetImageSize from '../../utilities/getimagesize/getimagesize';

function Scroller(props: any) {

    const { products } = useContext(AppContext);
    const [ current, SetCurrent ] = useState<number>(0);
    const [ listener, SetListener ] = useState<boolean>(false);

    // Make this useCallback so the reference is the same
    const cancelWheel = useCallback((event) => 
    {
        event.preventDefault();
    }, []);

    useEffect(() =>
    {
        return () => document.body.removeEventListener('wheel', cancelWheel);
    }, [])

    // Enable or disable the listener state when in range
    useEffect(() => 
    {
        if(props.content.scrollerItems && current < props.content.scrollerItems.length - 1)
        {
            SetListener(true);
        }
        else
        {
            SetListener(false);
        }
        
    }, [current, props.content.scrollerItems]);

    // Actually add or remove the listener when the state changes
    useEffect(() => 
    {
        if(listener)
        {
            document.body.addEventListener('wheel', cancelWheel, {passive: false});
        }
        else
        {
            document.body.removeEventListener('wheel', cancelWheel);
        }
        
    }, [listener]);

    const GetWrapperStyle = () =>
    {
        let style = { marginTop: 120 - (current * 72) }
        return style;
    }

    const GetContainerStyle = (index: number) =>
    {
        let style = { height:32, opacity: 1, cursor: 'pointer'}
        if(index == current) style = {height: 300, cursor:'normal', opacity: 1}

        if(index == current + 2 || index == current - 2) style.opacity = 0.5;
        if(index > current + 2 || index < current - 2) style.opacity = 0;

        return style;
    }

    const GetTitleStyle = (index: number) =>
    {
        let style = { fontSize: '1rem', fontWeight: 'normal' }
        if(index == current) style = {fontSize: '3rem', fontWeight: 'bold'}
        return style;
    }

    const GetTextStyle = (index: number) =>
    {
        let style = { opacity: 0 }
        if(index == current) style = {opacity: 1}
        return style;
    }

    const GetNumberStyle = (index: number) =>
    {
        let style = { width: '32px', height: '32px' }
        if(index == current) style = { width: '64px', height: '64px' }
        return style;
    }

    const GetBackgroundStyle = () =>
    {
        let style = { backgroundColor: '#3CA6A6' }
        
        if(current == 0) style.backgroundColor = '#026773';
        if(current == 1) style.backgroundColor = '#012E40';
        if(current == 2) style.backgroundColor = '#024959';
        if(current == 3) style.backgroundColor = '#3CA6A6';
        if(current == 4) style.backgroundColor = '#026773';
        if(current == 5) style.backgroundColor = '#012E40';
        if(current == 6) style.backgroundColor = '#024959';
        if(current == 7) style.backgroundColor = '#3CA6A6';
        if(current == 8) style.backgroundColor = '#026773';
        if(current == 9) style.backgroundColor = '#012E40';
        if(current == 10) style.backgroundColor = '#024959';

        return style;
    }

    const GetCurrentImage = () =>
    {
        let image = '';
        
        if(props.content.scrollerItems.length > current)
        {
            if(props.content.scrollerItems[current].image && props.content.scrollerItems[current].image.data) image = GetImageSize(props.content.scrollerItems[current].image, 'medium');
        }

        return image;
    }

    const ScrollThroughList = (e) =>
    {
        if(window.scrollY != 0) return;

        if(e.deltaY < 0)
        {
            if(current > 0)
            {
                SetCurrent(current - 1);
            }
        }
        else
        {
            if(props.content.scrollerItems && current < props.content.scrollerItems.length - 1)
            {
                SetCurrent(current + 1);
            }
        }
    }

    return (
        <>
            {props.content.scrollerItems &&
                <motion.div initial={{ opacity: 0, backgroundColor: '#3CA6A6', backgroundImage: 'none' }} animate={GetBackgroundStyle()} whileInView={{ opacity: 1 }} className='w-full min-h-screen bg-cyan-600 pt-20' onWheel={(e) => ScrollThroughList(e)}>
                    <ContentWrapper>
                        <div className='flex'>
                            <motion.div className='flex-grow' initial={GetWrapperStyle()} animate={GetWrapperStyle()}>
                                {props.content.scrollerItems.map((item:any, index:number) =>
                                    <motion.div key={'product-category-' + index} className='overflow-y-hidden text-white h-6 mb-10' initial={GetContainerStyle(index)} animate={GetContainerStyle(index)} onClick={() => SetCurrent(index)}>
                                        <div>
                                            <motion.h2 className='leading-none' initial={GetTitleStyle(index)} animate={GetTitleStyle(index)}>
                                                <motion.span animate={GetNumberStyle(index)} className='bg-cyan-500 inline-block size-8 pt-2 text-center rounded-full mr-6'>{index + 1}</motion.span>
                                                <span className='pt-2'>{item.title}</span>
                                            </motion.h2>
                                            <motion.div className='mt-8 max-w-3xl' initial={GetTextStyle(index)} animate={GetTextStyle(index)} transition={{delay: 0.2}}>
                                                <Markdown>{item.text}</Markdown>
                                            </motion.div>
                                        </div>
                                    </motion.div>
                                )}
                            </motion.div>
                            <div className='w-1/3 flex-shrink mt-32'>
                                <img src={GetCurrentImage()} className='w-full aspect-4/3 object-cover rounded-2xl shadow-xl' />
                            </div>
                        </div>
                    </ContentWrapper>
                </motion.div>
            }
        </>
    );
}

export default Scroller;