function Dots(props: any) {
    
    const GetColor = () =>
    {
        if(props.color == 'yellow') return 'bg-yellow-500';
        if(props.color == 'blue') return 'bg-sic-blue';
        return 'bg-yellow-500';
    }

    return (
        <>
            <div className={GetColor() + ' size-2 rounded-full inline-block mr-1'} />
            <div className={GetColor() + ' size-2 rounded-full inline-block mr-1'} />
            <div className={GetColor() + ' size-2 rounded-full inline-block mr-1'} />
        </>
    );
}

export default Dots;