import ContentWrapper from "../elements/contentwrapper/contentwrapper";
import { motion } from "framer-motion";
import Dots from "../elements/dots/dots";
import { useContext } from "react";
import { AppContext } from "../../App";
import GetImageSize from "../utilities/getimagesize/getimagesize";
import Markdown from "react-markdown";
import { NavLink } from "react-router-dom";
import GetPath from "../utilities/getpath/getpath";
import Socials from "../elements/socials/socials";

function Footer(props: any) {

    const { products, productsSlug, lang } = useContext(AppContext);

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                
                <div className='w-full py-20 bg-cover bg-center text-white' style={{backgroundImage: 'url('+ GetImageSize(props.content.background, 'large') + ')'}}>
                    <ContentWrapper>
                        <div className="flex-none lg:flex gap-20">
                            <motion.div className="pt-4 basis-0 grow"
                                initial={{ x: -100, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20
                                }}
                                viewport={{amount: 0.25}}
                            >
                                <div className="text-xl font-medium">{props.content.locationTitle}</div>
                                <div className="my-3"><Dots /></div>
                                <div className=""><span dangerouslySetInnerHTML={{__html: props.content.locationText}} /></div>
                                <div className="mt-8"><div className='bg-yellow-500 size-2 rounded-full inline-block mr-2' /> {props.info.phone}</div>
                                <div className="mt-3"><div className='bg-yellow-500 size-2 rounded-full inline-block mr-2' /> {props.info.email}</div> 
                                <div className="mt-3"><div className='bg-yellow-500 size-2 rounded-full inline-block mr-2' /> {props.info.address}</div>   
                            </motion.div>
                            <motion.div className="pt-4 basis-0 grow" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay:0.5 }}>
                                <img src={GetImageSize(props.content.image, 'small')} className="w-full max-w-lg mt-16" />
                            </motion.div>
                            <motion.div className="pt-16 lg:pt-4 basis-0 grow"
                                initial={{ x: 100, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20
                                }}
                                viewport={{amount: 0.25}}
                            >
                                <div className="text-xl font-medium">{props.content.socialTitle}</div>
                                <div className="my-3"><Dots /></div>
                                <div className=""><Markdown>{props.content.socialText}</Markdown></div>
                            </motion.div>
                        </div>
                    </ContentWrapper>
                </div>
            
                <ContentWrapper>
                    <div className="flex-none lg:flex gap-20 pt-20">
                        <div className="basis-0 grow mb-20">
                            <div className="text-xl font-medium">{props.content.aboutTitle}</div>
                            <div className="mt-3 mb-5"><Dots /></div>
                            <div className="text-sm text-neutral-500 grid grid-cols-1 md:grid-cols-2">
                                {props.content.links.data.map((link:any, index:number) =>
                                    <NavLink key={'footer-link-' + index} to={GetPath(lang, link.attributes.slug)} className="block pb-3 border-b-neutral-300 border-b mb-3">
                                        {link.attributes.title}
                                    </NavLink>
                                )}
                                
                            </div>
                        </div>
                        <div className="basis-0 grow mb-20">
                            <div className="text-xl font-medium">{props.content.productsTitle}</div> 
                            <div className="mt-3 mb-5"><Dots /></div>
                            <div className="text-sm text-neutral-500 grid grid-cols-1 md:grid-cols-2">
                                {products && products.map((product:any, index:number) =>
                                    <NavLink key={'footer-category-' + index} to={GetPath(lang, productsSlug, product.attributes.slug)} className="block pb-3 border-b-neutral-300 border-b mb-3">
                                        {product.attributes.name}
                                    </NavLink>
                                )}
                            </div>
                        </div>
                        <div className="basis-0 grow mb-20">
                            <div className="text-xl font-medium">{props.content.extraTitle}</div>
                            <div className="mt-3 mb-5"><Dots /></div>
                            <div className="">
                                <Markdown>{props.content.extraText}</Markdown>
                                <Socials />
                            </div>
                        </div>
                    </div>
                </ContentWrapper>

                <div className='bg-neutral-800 text-white text-xs uppercase h-12 pt-4 text-center'>
                    <ContentWrapper>
                        &copy; {new Date().getFullYear()} SIC. All rights reserved
                    </ContentWrapper>
                </div>

            </motion.div>
        </>
    );
}

export default Footer;