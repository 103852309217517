import { useContext, useEffect, useState, Fragment } from "react";
import { motion } from "framer-motion";
import ContentWrapper from "../elements/contentwrapper/contentwrapper";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../App";
import GetPath from "../utilities/getpath/getpath";
import axios from "axios";
import GetImageSize from "../utilities/getimagesize/getimagesize";
import Markdown from "react-markdown";

const logo = new URL('./../../img/sic-logo.svg', import.meta.url);
const hamburger = new URL('./../../img/icon-menu.svg', import.meta.url);
const phone = new URL('./../../img/icon-phone.svg', import.meta.url);
const email = new URL('./../../img/icon-email.svg', import.meta.url);

function Menu(props:any) 
{
    const { lang, SetLang, productsSlug, industriesSlug } = useContext(AppContext);
    const [products, SetProducts] = useState<any>(null);
    const [industries, SetIndustries] = useState<any>(null);
    const [submenuProducts, SetSubmenuProducts] = useState<any>(null);
    const [productsVisible, SetProductsVisible] = useState<boolean>(false);
    const [industriesVisible, SetIndustriesVisible] = useState<boolean>(false);
    const [menuVisible, SetMenuVisible] = useState<boolean>(false);

    // Get submenu for products
    useEffect(() => {  
        axios
            .get(process.env.STRAPI_URL + '/api/products?filters[parent][$null]=true&filters[slug][$notIn][0]=' + props.menu.submenuProduct.data.attributes.slug + '&filters[slug][$notIn][1]=' + props.menu.featuredProduct.data.attributes.slug + '&sort=order&locale=' + lang)
            .then((response) => {
                console.log(response.data.data);
                SetProducts(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang, props]);

    // Get hoses
    useEffect(() => {  
        axios
            .get(process.env.STRAPI_URL + '/api/products?filters[parent][slug][$eq]=' + props.menu.submenuProduct.data.attributes.slug + '&sort=order&locale=' + lang)
            .then((response) => {
                SetSubmenuProducts(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang, props]);

    // Get submenu for industries
    useEffect(() => {  
        axios
            .get(process.env.STRAPI_URL + '/api/industries?&sort=order&populate=icon&locale=' + lang)
            .then((response) => {
                SetIndustries(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang, props]);

    const ToggleProductsVisible = () =>
    {
        SetProductsVisible(!productsVisible);
    }

    const ToggleIndustriesVisible = () =>
    {
        SetIndustriesVisible(!industriesVisible);
    }

    return (
        <>
            <motion.div animate={{ opacity:1 }} initial={{ opacity: 0}}>
                <div className='bg-neutral-800 text-white text-xs uppercase'>
                    <ContentWrapper>
                        <div className="flex">
                            <div className="h-12 pt-4">
                                <span className="mr-6"><img src={phone.pathname} className="size-5 inline-block mr-2 -mt-0.5" />{props.info.phone}</span>
                                <span><a href={'mailto:' + props.info.email}><img src={email.pathname} className="size-5 inline-block mr-2 -mt-0.5" />{props.info.email}</a></span>    
                            </div>
                            <div className="h-12 pt-4 text-right grow hidden lg:block">
                                {props.languages.map((language:any) =>
                                    <NavLink to={GetPath(language.code, '')} onClick={() => SetLang(language.code)} className={language.code == lang ? 'ml-3 uppercase font-bold' : 'ml-3 uppercase'} key={language.code}>{language.code}</NavLink>
                                )}   
                            </div>
                        </div>
                    </ContentWrapper>
                </div>
                <div className='bg-white drop-shadow-lg text-neutral-700'>
                    <ContentWrapper>
                        <div className='h-24 flex'>
                            <div className='h-full table-cell w-36 flex-none'>
                                <NavLink to={GetPath(lang, '')} className='block h-full'><img src={logo.pathname} className='h-full' /></NavLink>
                            </div>
                            <div className='flex-1 w-full'>
                                <div className='h-24 w-full text-right pt-9'>
                                    {props.menu.pages.data &&
                                        <>
                                            <div className="block lg:hidden w-full">
                                                <div className="cursor-pointer w-full text-right" onClick={() => SetMenuVisible(!menuVisible)}>
                                                    <img src={hamburger.pathname} className="inline-block w-8 -mt-1" />
                                                </div>
                                            </div>
                                            <div className="hidden lg:block">
                                                {props.menu.pages.data.map((menuItem: any, index:number) =>
                                                    <Fragment key={'menu-' + index}>
                                                        {menuItem.attributes.slug != productsSlug && menuItem.attributes.slug != industriesSlug &&
                                                            <NavLink className={({ isActive }) => isActive ? 'ml-8 font-bold last:bg-sic-blue last:text-white last:py-2 last:px-4 last:rounded-xl last:uppercase last:text-sm' : 'ml-8 last:font-bold last:bg-sic-blue last:text-white last:py-2 last:px-4 last:rounded-xl last:uppercase last:text-sm'} to={GetPath(lang, menuItem.attributes.slug)}>{menuItem.attributes.title}</NavLink>
                                                        }
                                                        {menuItem.attributes.slug == productsSlug &&
                                                            <div className="ml-8 relative inline-block pb-2 cursor-pointer" onClick={() => ToggleProductsVisible()} onMouseLeave={() => SetProductsVisible(false)}>
                                                                {menuItem.attributes.title}
                                                                {products && productsVisible && submenuProducts && 
                                                                    <div className="bg-white absolute cursor-default px-12 mt-2 -ml-80 pt-8 pb-6 text-left rounded-xl shadow-slide flex gap-12">
                                                                        <div className="py-3 w-64">
                                                                            <NavLink to={GetPath(lang, menuItem.attributes.slug, props.menu.featuredProduct.data.attributes.slug)}>
                                                                            <div className="font-bold mb-4">{props.menu.featuredProduct.data.attributes.name}</div>
                                                                            <div className="text-sic-blue mb-4"><Markdown>{props.menu.featuredText}</Markdown></div>
                                                                            <img src={GetImageSize(props.menu.featuredImage, 'small')} className="block w-64" />
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="w-px bg-neutral-200" />
                                                                        <div className="py-3">
                                                                            <div className="font-bold mb-4">{menuItem.attributes.title}</div>
                                                                            {products.map((product:any, productIndex:number) =>
                                                                                <NavLink to={GetPath(lang, menuItem.attributes.slug, product.attributes.slug)} key={'menu-product-' + productIndex} className={({ isActive }) => isActive ? 'whitespace-nowrap block mb-1 font-bold' : 'whitespace-nowrap block mb-1'}>{product.attributes.name}</NavLink>
                                                                            )}
                                                                        </div>
                                                                        <div className="w-px bg-neutral-200" />
                                                                        <div className="py-3">
                                                                            <div className="font-bold mb-4">{props.menu.submenuProduct.data.attributes.name}</div>
                                                                            {submenuProducts.map((product:any, productIndex:number) =>
                                                                                <NavLink to={GetPath(lang, menuItem.attributes.slug, product.attributes.slug)} key={'menu-product-' + productIndex} className={({ isActive }) => isActive ? 'whitespace-nowrap block mb-1 font-bold' : 'whitespace-nowrap block mb-1'}>{product.attributes.name}</NavLink>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {menuItem.attributes.slug == industriesSlug &&
                                                            <div className="ml-8 relative inline-block pb-2 cursor-pointer" onClick={() => ToggleIndustriesVisible()} onMouseLeave={() => SetIndustriesVisible(false)}>
                                                                {menuItem.attributes.title}
                                                                {industries && industriesVisible &&  
                                                                    <div className="bg-white absolute cursor-default w-[576px] p-12 mt-2 -ml-[288px] text-center rounded-xl shadow-slide flex flex-wrap gap-12">
                                                                        {industries.map((industry:any, industryIndex:number) =>
                                                                            <NavLink 
                                                                                to={GetPath(lang, menuItem.attributes.slug, industry.attributes.slug)} 
                                                                                key={'menu-industry-' + industryIndex} 
                                                                                className={({ isActive }) => isActive ? 'block w-32 font-bold' : 'block w-32'}
                                                                            >
                                                                                <img src={GetImageSize(industry.attributes.icon, 'small')} className="mb-4 w-12 block mx-auto" />
                                                                                {industry.attributes.title}
                                                                            </NavLink>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </Fragment>
                                                )}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </ContentWrapper>
                        {menuVisible &&
                            <div className="py-8 leading-relaxed bg-neutral-100">
                                <ContentWrapper>
                                    {props.menu.pages.data.map((menuItem: any, index:number) =>
                                        <Fragment key={'menu-mobile-' + index}>
                                            {menuItem.attributes.slug != productsSlug && menuItem.attributes.slug != industriesSlug && 
                                                <NavLink className={({ isActive }) => isActive ? 'font-bold block' : 'block'} to={GetPath(lang, menuItem.attributes.slug)} onClick={() => SetMenuVisible(false)}>{menuItem.attributes.title}</NavLink>
                                            }
                                            {menuItem.attributes.slug == productsSlug &&
                                                <>
                                                    {menuItem.attributes.title}
                                                    <NavLink className={({ isActive }) => isActive ? 'font-bold block ml-8' : 'block ml-8'} to={GetPath(lang, productsSlug, props.menu.featuredProduct.data.attributes.slug)} onClick={() => SetMenuVisible(false)}>{props.menu.featuredProduct.data.attributes.name}</NavLink>
                                                    {products && products.map((product:any, productIndex:number) =>
                                                        <NavLink to={GetPath(lang, menuItem.attributes.slug, product.attributes.slug)} key={'menu-mobile-product-' + productIndex} className={({ isActive }) => isActive ? 'font-bold block ml-8' : 'block ml-8'} onClick={() => SetMenuVisible(false)}>{product.attributes.name}</NavLink>
                                                    )}
                                                    <div className="ml-8">{props.menu.submenuProduct.data.attributes.name}</div>
                                                    {submenuProducts.map((product:any, productIndex:number) =>
                                                        <NavLink to={GetPath(lang, menuItem.attributes.slug, product.attributes.slug)} key={'menu-mobile-product-' + productIndex} className={({ isActive }) => isActive ? 'font-bold block ml-16' : 'block ml-16'} onClick={() => SetMenuVisible(false)}>{product.attributes.name}</NavLink>
                                                    )}
                                                </>
                                            }
                                            {menuItem.attributes.slug == industriesSlug &&
                                                <>
                                                    {menuItem.attributes.title}
                                                    {industries && industries.map((industry:any, industryIndex:number) =>
                                                        <NavLink to={GetPath(lang, menuItem.attributes.slug, industry.attributes.slug)} key={'menu-mobile-industry-' + industryIndex} className={({ isActive }) => isActive ? 'font-bold block ml-8' : 'block ml-8'} onClick={() => SetMenuVisible(false)}>{industry.attributes.title}</NavLink>
                                                    )}
                                                </>
                                            }
                                        </Fragment>
                                    )}
                                    <div className="mt-8">
                                        {props.languages.map((language:any) =>
                                            <NavLink to={GetPath(language.code, '')} onClick={() => SetLang(language.code)} className={language.code == lang ? 'mr-3 uppercase font-bold' : 'mr-3 uppercase'} key={language.code}>{language.code}</NavLink>
                                        )}  
                                    </div>
                                </ContentWrapper>
                            </div>
                        }
                </div>
            </motion.div>
        </>
    );
}

export default Menu;