import ContentWrapper from "../elements/contentwrapper/contentwrapper";

function Error(props: any) {

    return (
        <ContentWrapper>
            Error
        </ContentWrapper>
    );
}

export default Error;